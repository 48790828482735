// VENDOR STYLES
// -----------------------------------------------------------------------------

// THEME VARIABLES
// -----------------------------------------------------------------------------
//@import "../app/config/variables";

// BOOTSTRAP
// -----------------------------------------------------------------------------
//@import "bootstrap/bootstrap";

// VENDOR PLUGINS
// -----------------------------------------------------------------------------
//@import "svg-uri/svg-uri";
//@import "fontawesome/fontawesome";
//@import "fontawesome/regular";
//@import "fontawesome/solid";
//@import "animate/animate";

// THEME VENDOR
// -----------------------------------------------------------------------------
@import "theme/vendor";
